<template>
  <main>
    <v-container>
      <v-row justify="space-around">
        <v-card width="100%">
          <v-card-text>
            <div class="font-weight-bold ml-8 mb-2">Dokumentasi</div>

            <v-timeline align-top dense>
              <v-timeline-item v-for="message in messages" :key="message.time" :color="message.color" small>
                <div>
                  <div class="font-weight-normal">
                    <strong>{{ message.from }}</strong> @{{ message.time }}
                  </div>
                  <div>{{ message.message }}</div>
                </div>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
        </v-card>
      </v-row>
    </v-container>
  </main>
</template>
<script>
export default {
  data() {
    return {
      messages: [
        {
          from: 'You',
          message: `Sure, I'll see you later.`,
          time: '10:42am',
          color: '#FF00AE',
        },
        {
          from: 'John Doe',
          message: 'Yeah, sure. Does 1:00pm work?',
          time: '10:37am',
          color: '#FF8800',
        },
        {
          from: 'You',
          message: 'Did you still want to grab lunch today?',
          time: '9:47am',
          color: '#5F67FF',
        },
      ],
    }
  },
}
</script>
